import React from "react";
import Hiring from "../../components/Hiring/Hiring";
import LayoutHiring from "../../layouts/LayoutHiring"

const Home = () => {
  return (
    <LayoutHiring>
      <Hiring />
    </LayoutHiring>
  );
};

export default Home;
