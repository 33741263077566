import React from "react";
import { NavLink } from "react-router-dom";
import worksCardEffect from "../../common/worksCardEffect";
import One from "../../assets/img/full/01.webp";
import Two from "../../assets/img/full/02.webp";
import Three from "../../assets/img/full/03.webp";
import Four from "../../assets/img/full/04.webp";

const Portfolio2 = () => {
  React.useEffect(() => {
    worksCardEffect();
  }, []);

  return (
    <section id = "portfolio" className="portfolio full-bg">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-3 col-md-6 cluom" data-tab="tab-1">
            <div className="info" style={{ padding:"10%", }}>
              <h6 className="custom-font">HİZMETLERİMİZ</h6>
              <h4>ETKİNLİK YÖNETİMİ</h4>
              <p className="portfolio-p" >Özel ve kurumsal etkinliklerin planlanması, yönetimi ve gerçekleştirilmesi alanlarında hizmet veriyoruz. <br/>Konserler, fuarlar, konferanslar, lansmanlar ve özel davetler gibi çeşitli etkinliklerinizi kusursuz bir şekilde organize ediyoruz.<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></p>
            </div>
            {/* <div className="more">
              <NavLink to="/project-details">
                Etkinlik Detayları <i className="fas fa-chevron-right"></i>
              </NavLink>
            </div> */}
          </div>
          <div className="col-lg-3 col-md-6 cluom" data-tab="tab-2">
            <div className="info" style={{ padding:"10%", }}>
              <h6 className="custom-font">HİZMETLERİMİZ</h6>
              <h4>ETKİNLİK STAND & ALAN TASARIMI</h4>
              <p className="portfolio-p" >Firmanızın kurumsal kültürünü, gücünü ve ürün içeriklerini kusursuzca anlatabilecek standlar yaratıyor ve etkinliklerde en iyi şekilde firmanızı temsil etmenize yardımcı oluyoruz.<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></p>
            </div>
            {/* <div className="more">
              <NavLink to="/project-details">
                Etkinlik Detayları <i className="fas fa-chevron-right"></i>
              </NavLink>
            </div> */}
          </div>
          <div className="col-lg-3 col-md-6 cluom" data-tab="tab-3">
            <div className="info" style={{ padding:"10%", }}>
              <h6 className="custom-font">HİZMETLERİMİZ</h6>
              <h4>YAPAY ZEKA ÇÖZÜMLERİ</h4>
              <p className="portfolio-p" >Etkinliklerimizde yapay zeka tabanlı teknolojiler kullanarak katılımcıların tercihlerini analiz ediyor, yönetim problemlerini çözüyor ve kişiselleştirilmiş deneyimler sunuyoruz. <br/>Yapay zeka destekli ürünler  ve akıllı etkileşimlerle etkinlik süreçlerini optimize ediyoruz.<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></p>
            </div>
            {/* <div className="more">
              <NavLink to="/project-details">
                Etkinlik Detayları <i className="fas fa-chevron-right"></i>
              </NavLink>
            </div> */}
          </div>
          <div className="col-lg-3 col-md-6 cluom" data-tab="tab-4">
            <div className="info" style={{ padding:"10%", }}>
              <h6 className="custom-font">HİZMETLERİMİZ</h6>
              <h4>ÜRÜN MAKETLERİ</h4>
              <p className="portfolio-p" >Etkinliklerinizdeki katılımcılara hediye edebileceğiniz özel ürünlerinizin maketlerini sizler için üretiyoruz. <br/>Ürünlerinizin maketlerine, katılımcıların teknolojilerinizle etkileşim kurabilecekleri ve yenilikçi bakış açıları kazanmalarını sağlayacak entegrasyonu sağlıyoruz.<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/></p>
            </div>
            {/* <div className="more">
              <NavLink to="/project-details">
                Etkinlik Detayları <i className="fas fa-chevron-right"></i>
              </NavLink>
            </div> */}
          </div>
        </div>
      </div>
      <div className="glry-img">
        <div
          id="tab-1"
          className="bg-img tab-img current"
          style={{ backgroundImage: `url(${One})` }}
          data-overlay-dark="2"
        ></div>
        <div
          id="tab-2"
          className="bg-img tab-img"
          style={{ backgroundImage: `url(${Two})` }}
          data-overlay-dark="2"
        ></div>
        <div
          id="tab-3"
          className="bg-img tab-img"
          style={{ backgroundImage: `url(${Three})` }}
          data-overlay-dark="2"
        ></div>
        <div
          id="tab-4"
          className="bg-img tab-img"
          style={{ backgroundImage: `url(${Four})` }}
          data-overlay-dark="2"
        ></div>
      </div>
    </section>
  );
};

export default Portfolio2;
