import React from "react";
// import icon01 from "../../assets/img/icon/h2_choose_icon01.svg";
import icon01 from "../../assets/img/icon/1.webp";
import icon02 from "../../assets/img/icon/2.webp";
import icon03 from "../../assets/img/icon/3.webp";
import icon04 from "../../assets/img/icon/4.webp";
import WhyChooseUsTwoItem from "./WhyChooseUsTwoItem";

const WhyChooseUsTwo = () => {
  const items = [
    {
      src: icon01,
      title: "Hız",
      desc: "Müşterilerimizin projelerini zamanında tamamlamak için hızlı ve etkin bir şekilde çalışıyoruz. Etkinlik süreçlerini verimli bir şekilde yöneterek, müşterilerimizin beklenen teslim tarihlerine uygun bir şekilde sonuç almalarını sağlıyoruz.",
      url: "/",
    },
    {
      src: icon02,
      title: "Çözüm Ortaklığı",
      desc: "Müşterilerimizle uzun vadeli ve sürdürülebilir bir ilişki kurmayı hedefleriz. Bir çözüm ortağı olarak, müşterilerimizin hedeflerini, zorluklarını ve beklentilerini anlamak için işbirliği içinde çalışarak başarılı sonuçlar elde etmemizi sağlayan güvenilir bir çözüm ortaklığı oluşturmaktayız.",
      url: "/",
    },
    {
      src: icon03,
      title: "İnovasyon Ürünleri",
      desc: "Etkinliklerinizi unutulmaz ve etkileyici hale getirecek yaratıcı fikirler sunmak için sürekli olarak yeni trendleri takip ediyor ve yapay zeka entegrasyonu bulunan inovatif çözümler geliştiriyor, var olan ürünlerimizi kullanmanızı sağlıyoruz.",
      url: "/",
    },
    {
      src: icon04,
      title: "Hizmet Kalitesi",
      desc: "Müşterilerimizin hizmet kalitesine yaklaşımını yakından takip ediyoruz. Müşteri geri bildirimlerine önem veriyor ve iyileştirme süreçlerimize dahil ediyoruz. Müşterilerimizin olumlu bir deneyim yaşamaları ve bizi güvenle tercih etmeleri için sürekli olarak hizmet kalitemizi arttırmak için çaba gösteriyoruz.",
      url: "/",
    },
  ];

  return (
    <section id="services" className="choose-area-two choose-bg pb-130">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-6">
            <div className="section-title text-center mb-70">
              {/* <span className="sub-title">HİZMETLERİMİZ</span> */}
              <h2 className="title">
                NEDEN <span>İZ360 ?</span>
              </h2>
            </div>
          </div>
        </div>

        <div className="row">
          {items.map((x, index) => (
            <div key={index} className="col-lg-3 col-sm-6">
              <WhyChooseUsTwoItem item={x} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUsTwo;
