import React, { useState } from "react";
import aboutImg from "../../assets/img/images/h2_about_img.webp";
import aboutImg2 from "../../assets/img/images/h2_about_img_2.webp";
import aboutImg3 from "../../assets/img/images/h2_about_img_3.webp";

const Sales = () => {
  return (
    <section className="chart-area chart-bg">
      <div id="sales" className="section-title text-center mb-30">
        <span className="sub-title">ÜRÜNLERİMİZ</span>
        <h2 className="title">NELER SUNUYORUZ ?</h2>
      </div>

      <div className="container custom-container-four pt-60">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-img-two wow fadeInLeft" data-wow-delay=".2s">
              <img src={aboutImg2} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-15">
                <span className="sub-title" style={{fontWeight:"900"}}>GÖRÜNTÜ İŞLEME ARACI</span>
              </div>
              <p style={{ marginBottom: "20px", minWidth: "103%" }}>
                Gelişmiş görüntü işleme teknolojisi ve yapay zeka algoritmaları
                sayesinde etkinliklerinizdeki insan trafiğini göz açıp kapayana
                kadar saymanızı sağlamaktadır. Kalabalıknoktaları kontrol
                etmek, katılımcı sayılarını takip etmek ve etkinlik alanını en
                verimli şekilde kullanmak için algoritmanın sunduğu öneri
                sistemi ile etkinliğinizi çok daha kolay şekilde yönetebilmenizi
                sağlamaktadir.
              </p>
              <div className="about-list">
                <ul>
                  <li>
                    <i className="fas fa-check"></i>
                    <p>
                      <i style={{ fontStyle: "normal", fontWeight:"500" }}>
                        Anlık Verilerle Etkili Planlama:
                      </i>{" "}
                      Gerçek zamanlı sayım verileri sayesinde mekanın ne kadar
                      dolu olduğunu takip edebilir ve etkinlik akışınızı buna
                      göre yönlendirebilirsiniz. Kalabalık noktaları daha
                      verimli bir şekilde yönetmenize ve aksiyonel planlarınız
                      için yapay zekanın sunduğu öneri sistemi ile birçok
                      problemi öncesinde engellemenize olanak sağlamaktadır.{" "}
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    <p>
                      <i style={{ fontStyle: "normal", fontWeight:"500" }}>Keskin Doğruluk:</i>
                      Hassas görüntü işleme algoritmalarımız sayesinde,
                      kalabalıkların sayımındaki hataları minimuma indiriyoruz.
                      Bu da size güvenilir ve kesin sonuçlar sunarak etkinlik
                      verimliliğini artırmanıza yardımcı olmaktadır.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    <p>
                      <i style={{ fontStyle: "normal", fontWeight:"500" }}>Hız ve Performans:</i>{" "}
                      Akıllı işleme teknolojisi sayesinde, anında sayım
                      sonuçlarına ulaşırsınız. Gecikme olmadan gerçek zamanlı
                      verilere erişebilir ve hızla hareket eden kalabalıkları
                      kolaylıkla takip edebilirsiniz.{" "}
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    <p>
                      <i style={{ fontStyle: "normal", fontWeight:"500" }}>Esnek Kurulum:</i>{" "}
                      Ürünümüz, açık alan etkinliklerinizin gereksinimlerine
                      göre esnek bir şekilde kurulabilir. Birden fazla kamera
                      kullanarak etkinlik alanını genişletebilir ve
                      etkinliğinizin her köşesini kapsayabilirsiniz.
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    <p>
                      <i style={{ fontStyle: "normal", fontWeight:"500" }}>
                        Güvenilir ve Aktif Veri Koruma:
                      </i>{" "}
                      Katılımcıların gizliliğini koruyan ve verileri güvenle
                      uzak sunucularda saklayan sistemimizle etkinlikleriniz
                      güvenle yönetilir.
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container custom-container-four pt-60">
        <div className="row align-items-center">
          <div className="col-lg-6 order-lg-last">
            {" "}
            {/* Added "order-lg-last" class */}
            <div
              className="about-img-two text-end wow fadeInRight" /* Changed "fadeInLeft" to "fadeInRight" */
              data-wow-delay=".2s"
            >
              <img src={aboutImg} alt="" />
            </div>
          </div>
          <div className="col-lg-6 order-lg-first">
            {" "}
            {/* Added "order-lg-first" class */}
            <div
              className="about-content-two wow fadeInLeft" /* Changed "fadeInRight" to "fadeInLeft" */
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-15">
                <span className="sub-title" style={{ fontWeight:"900" }}>
                  YAPAY ZEKA DESTEKLİ ETKİNLİK VE NETWORK YÖNETİM UYGULAMASI
                </span>
              </div>
              <div className="about-list">
                <ul>
                  <li>
                    <i className="fas fa-check"></i>
                    <p>
                      <i style={{ fontStyle: "normal", fontWeight:"500" }}>
                        Etkinlik Yönetim ve Takip Modülü{" "}
                      </i>
                      <p className="defination">
                        {">"} Etkinliklerinizin yönetimi için 360 derecelik bir
                        yaklaşımla tüm yapmanız gerekenlerin size özel modülü,
                      </p>
                      <p className="defination" style={{ marginTop: "10px" }}>
                        {">"} Etkinliklerinizin takibi ve yönetimi ile gözden
                        kaçırdığınız hiçbir detayın olmaması temel prensip
                        olarak kurgulanmıştır
                      </p>
                      <br />{" "}
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    <p>
                      <i style={{ fontStyle: "normal", fontWeight:"500" }}>
                        Verimli Network Modülü{" "}
                      </i>
                      <p className="defination">
                        {">"} Etkinliğinizdeki tüm katılımcıları görüşme zaman
                        aralıklarına ve sektörlere göre eşleştirerek görüşme
                        önerileri,
                      </p>
                      <p className="defination" style={{ marginTop: "10px" }}>
                        {">"} Katılımcılar bir araya geldiğinde görüşme
                        alanlarına yönelik yeni öneriler,
                      </p>
                      <p className="defination" style={{ marginTop: "10px" }}>
                        {">"} Görüşme sonrası bireysel profilde kalacak görüşme
                        notları alanı,
                      </p>
                      <br />{" "}
                    </p>
                  </li>
                  <li>
                    <i className="fas fa-check"></i>
                    <p>
                      <i style={{ fontStyle: "normal", fontWeight:"500" }}>
                        Etkinlik Haritalandırma Modülü{" "}
                      </i>
                      <p className="defination">
                        {">"} Etkinliğinizin tüm alanlarını dijital olarak
                        ayrılmıştır.
                      </p>
                      <p className="defination" style={{ marginTop: "10px", paddingRight: "90px" }}>
                        {">"} Katılımcılarınız tercih ettiği alan gidene kadar
                        konum desteğinden faydalanabilmektedir.
                      </p>
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container custom-container-four pt-60">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div
              className="about-img-two textwow fadeInLeft"
              data-wow-delay=".2s"
            >
              <img src={aboutImg3} alt="" />
            </div>
          </div>
          <div className="col-lg-6">
            <div
              className="about-content-two wow fadeInRight"
              data-wow-delay=".2s"
            >
              <div className="section-title section-title-two mb-15">
                <span className="sub-title" style={{ fontWeight:"900"}}>MAKET ÜRÜN HAZIRLAMA</span>
              </div>
              <p style={{ marginBottom: "20px", minWidth: "103%" }}>
              Etkinliklerinizdeki katılımcılara hediye edebileceğiniz özel ürünlerinizin maketlerini sizler için üretiyoruz.
              Ürünlerinizin maketlerine, katılımcıların teknolojilerinizle etkileşim kurabilecekleri ve yenilikçi bakış açıları kazanmalarını sağlayacak entegrasyonu sağlıyoruz.
              </p>
                <div className="about-list">
                  <ul>
                    <li>
                      <i className="fas fa-check"></i>
                      <p>
                        <i style={{ fontStyle: "normal", fontWeight:"500", paddingTop:"100px"}}>
                          Etkinliğe Özel Promosyon Ürün
                        </i>
                      </p>
                    </li>
                    <li>
                      <i className="fas fa-check"></i>
                      <p>
                        <i style={{ fontStyle: "normal", fontWeight:"500", paddingTop:"100px"}}>
                          Etkileşimli ve Yenilikçi Maketler
                        </i>
                      </p>
                    </li>
                  </ul>
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Sales;
