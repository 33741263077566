import React, {useState} from "react";
import {useNavigate} from "react-router-dom";
import axios from "axios";

const ContactTwoForm = () => {
    const navigate = useNavigate()
    const [adSoyad, setAdSoyad] = useState()
    const [mailAdres, setMailAdres] = useState()
    const [mesaj, setMesaj] = useState()
    const [textColor, setTextColor] = useState()

    const [adSoyadActive, setAdSoyadActive] = useState()
    const [mailActive, setMailActive] = useState()
    const [mesajActive, setMesajActive] = useState()

    const sendMail = async (event) => {
        event.preventDefault()
        // navigate("/")
        console.log(adSoyad)
        console.log(mailAdres)
        console.log(mesaj)

        const formData = {ad_soyad: adSoyad, mail_adres: mailAdres, mesaj: mesaj}

        axios.post("https://iz360.com.tr/" + `send-mail`, formData).then(res=>{
            window.location.reload()
        })
        // console.log(response)



    }

  return (
    <div className="contact-form-wrap-two wow fadeInRight" data-wow-delay=".2s">
        <h2 className="title" style={{ textAlign: "center" }}>BİZİMLE İLETİŞİME GEÇİN !</h2>
      <form onSubmit={sendMail} action={"#"} >
        <input style={{color:adSoyadActive?"black":"white"}} onBlur={()=>setAdSoyadActive(false)} onFocus={()=>setAdSoyadActive(true)} type="text" value={adSoyad} onChange={event => {setAdSoyad(event.target.value)}} placeholder="İsim Soyisim" required />
        <input style={{color:mailActive?"black":"white"}} onBlur={()=>setMailActive(false)} onFocus={()=>setMailActive(true)} value={mailAdres} onChange={event => {setMailAdres(event.target.value)}} type="email" placeholder="Mail Adresi" required />
        <textarea style={{color:mesajActive?"black":"white"}} onBlur={()=>setMesajActive(false)} onFocus={()=>setMesajActive(true)} value={mesaj} onChange={event => {setMesaj(event.target.value)}} name="message" placeholder="Mesaj" required />
        <div className="col-lg-6" style={{ textAlign: "center" }}>
          <button type="submit" className="btn btn-two" >
            MESAJ GÖNDER
          </button>
        </div>
      </form>
    </div>
  );
};

export default ContactTwoForm;
