import React, { useEffect } from "react";
import shape01 from "../../assets/img/banner/banner_shape01.webp";
import shape02 from "../../assets/img/banner/banner_shape02.webp";
import shape03 from "../../assets/img/banner/banner_shape03.webp";
import CountDownOne from "../CountDown/CountDownOne";

const Banner = (props) => {
  useEffect(() => {
    return () => {
      const videoElement = document.getElementById("banner-video");
      if (videoElement) {
        videoElement.pause();
      }
    };
  }, []);

  return (
    <section className="banner-area banner-bg">
      <div className="banner-shape-wrap">
        <img src={shape01} alt="" className="img-one" />
        <img src={shape02} alt="" className="img-two" />
        <img src={shape03} alt="" className="img-three" />
      </div>

      {/* <video
        autoPlay
        muted
        loop
        className="banner-video"
        id="banner-video"
        style={{
          width: "100%",
          height: "100%",
          objectFit: "cover",
        }}
      >
        <source src={require("../../assets/videos/1.mp4")} type="video/mp4" />
      </video> */}

      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="banner-content text-center">
              <h2 className="title" style={{ paddingTop: "150px" }}>
                <span>İZ360</span> <br/>ETKİNLİK YÖNETİMİ VE İNOVASYON
              </h2>
              <p className="slogan">Yeni Bir İz</p>
            </div>
          </div>
        </div>
      </div>
  

      {/* <div className="row justify-content-center pt-110">
          <div className="col-xl-10">
            <div className="banner-countdown-wrap text-center">
              <h2 className="title">Bir Sonraki Etkinlik İçin Son ...</h2>

              <CountDownOne />
            </div>
          </div>
        </div> */}
    </section>
  );
};

export default Banner;

