import React from "react";
import adress_icon from "../../assets/img/icon/adress_icon.webp";
import tel_icon from "../../assets/img/icon/tel_icon.webp";
import email_icon from "../../assets/img/icon/email_icon.webp";

const ContactTwoInfo = () => {
  const info_items = [
    {
      src: adress_icon,
      info: (<>
        Ünalan Mahallesi <br />
        Üsküdar / İstanbul
      </>),
    },
    {
      src: tel_icon,
      info: "+90 530 663 15 99",
    },
    {
      src: email_icon,
      info: "iletisim@iz360.com.tr",
    },
  ];

  return (
    <div className="contact-info-wrap-two wow fadeInLeft" data-wow-delay=".2s">
      <h1 className="title">İletişim Bilgileri</h1>

      {info_items.map((x, index) => (
        <div key={index} className="contact-list-item">
          <div className="icon">
            <img src={x.src} alt="" />
          </div>
          <div className="content">
            <p>{x.info}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ContactTwoInfo;
