import { Link } from "react-router-dom";
import React from "react";

const WhyChooseUsTwoItem = (props) => {
  const itemStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "justify",
    maxWidth: "400px",
    margin: "0 auto",
    paddingBottom: "10%",
  };

  const iconStyles = {
    marginBottom: "40px",
  };

  const titleStyles = {
    textAlign: "center",
  };

  return (
    <div className="choose-item-two wow fadeInUp" data-wow-delay=".2s" style={itemStyles}>
      <div className="choose-icon-two" style={iconStyles}>
        <img src={props.item.src} alt={props.item.title} />
      </div>
      <div className="choose-content" style={titleStyles}>
        <h2 className="title">
          <Link to={props.item.url}>{props.item.title}</Link>
        </h2>
        <p>{props.item.desc}</p>
      </div>
    </div>
  );
};

export default WhyChooseUsTwoItem;
