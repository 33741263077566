import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo2.webp";

const AnimatedText = ({ textToAnimate, intervalTime, delay }) => {
  const [animatedText, setAnimatedText] = useState("");

  useEffect(() => {
    const delayTimeout = setTimeout(() => {
      let currentIndex = 0;

      const interval = setInterval(() => {
        setAnimatedText((prevText) => {
          if (currentIndex === textToAnimate.length) {
            clearInterval(interval);
            return prevText;
          }

          const newText = prevText + textToAnimate[currentIndex];
          currentIndex++;
          return newText;
        });
      }, intervalTime);

      return () => {
        clearInterval(interval);
      };
    }, delay);

    return () => {
      clearTimeout(delayTimeout);
    };
  }, [textToAnimate, intervalTime, delay]);

  return animatedText;
};

const sendEmail = () => {
  // E-posta adresini ve konuyu tanımlayın
  const email = "iletisim@iz360.com.tr";
  const subject = "Konu";

  // E-posta adresini ve konuyu URL ile kodlayın
  const emailLink = "mailto:" + email + "?subject=" + encodeURIComponent(subject);

  // E-posta linkini yeni bir pencerede açın
  window.open(emailLink, "_blank");
};

const Hiring = () => {
  return (
    <section className="choose-area-two hiring-bg pb-130">
      <div className="container custom-container-four">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="section-title text-center mb-70">
              <span className="sub-title">İŞ İLANLARI</span>
              <h2 className="title pt-60">
                <AnimatedText
                  textToAnimate="ÇOK YAKINDA SİZLERLE BİRLİKTE OLACAĞIZ."
                  intervalTime={120}
                  delay={1000} // Waiting time before the animation starts (in milliseconds)
                />
              </h2>
              <p className="hiring-text">
                <AnimatedText
                  textToAnimate="LÜTFEN SABIRLA BEKLEYİNİZ."
                  intervalTime={120}
                  delay={6500} // Waiting time before the animation starts (in milliseconds)
                />
              </p>
            </div>
          </div>
          <div className="col-lg-12 page-bottom">
            <div className="footer-top">
              <div className="row">
                <div
                  className="col-xl-3 col-lg-4 col-md-12 responsive-logo"
                  style={{ justifyContent: "center", display: "flex" }}
                >
                  <div
                    className="footer-widget wow fadeInUp"
                    data-wow-delay=".2s"
                  >
                    <Link
                      to="/"
                      className="f-logo"
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        marginBottom: "25px",
                      }}
                    >
                      <img src={logoImage} alt="İZ360 Logo" />
                    </Link>
                    <div className="footer-content">
                      <p>İZ360 | Etkinlik Yönetimi, İnovasyon</p>
                      <ul className="footer-social">
                        <li>
                          <a href="https://www.instagram.com/iz360_grup/" target="_blank">
                            <i className="fab fa-instagram"></i>
                          </a>
                        </li>
                        <li>
                          <a href="https://www.linkedin.com/company/iz360/" target="_blank">
                            <i className="fab fa-linkedin"></i>
                          </a>
                        </li>
                        <li>
                          <a href="/hiring">
                            <i className="fas fa-users"></i>
                          </a>
                        </li>
                        <li>
                          <a onClick={sendEmail}>
                            <i className="fas fa-envelope"></i>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-4 col-md-6 responsive-footer">
                  <div
                    className="footer-widget wow fadeInUp"
                    data-wow-delay=".8s"
                  >
                    <h4 className="fw-title">İŞ İMKANLARIMIZDAN İLK SİZ HABERDAR OLUN</h4>
                    <div className="footer-newsletter">
                      <p>
                        Mail adresinizi göndererek bültene kayıt olun ve
                        yaptıklarımızdan ve iş ilanlarından ilk siz haberdar olun !
                      </p>
                      <form action="#">
                        <input
                          type="email"
                          placeholder="info@gmail.com"
                          required
                        />
                        <button type="submit">
                          <i className="fas fa-paper-plane"></i>
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hiring;
