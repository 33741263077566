import React from "react";
import Prep from "../../components/Prep/Prep";
import LayoutHiring from "../../layouts/LayoutHiring"

const Home = () => {
  return (
    <LayoutHiring>
      <Prep />
    </LayoutHiring>
  );
};

export default Home;
