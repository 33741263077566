import React from "react";
import Banner from "../../components/Banner/Banner";
// import ContactOne from "../../components/Contact/ContactOne";
import ContactTwo from "../../components/Contact/ContactTwo";
// import Roadmap from "../../components/Roadmap/Roadmap";
import Sales from "../../components/Sales/Sales";
// import TeamOne from "../../components/Team/TeamOne";
// import CounterArea from "../../components/CounterArea/CounterArea";
// import TopPartners from "../../components/TopPartners/TopPartners";
// import AfterBefore from "../../components/AfterBefore/AfterBefore";
// import WhitePaper from "../../components/WhitePaper/WhitePaper";
import WhoWeAre from "../../components/WhoWeAre/WhoWeAre";
import WhyChooseUs from "../../components/WhyChooseUs/WhyChooseUsTwo";
import LayoutOne from "../../layouts/LayoutOne";
import Portfolio from "../../components/Portfolio/Portfolio";
// import Testimotionals from "../../components/Testimonials/Testimotionals";

const Home = () => {
  return (
    <LayoutOne>
      <main className="fix">
        <Banner />  
        <WhoWeAre />
        <Portfolio />
        {/* <TeamOne /> */}
        <WhyChooseUs />
        {/* <CounterArea/> */}
        <Sales />
        {/*<AfterBefore/>*/}
        {/* <TopPartners /> */}
        {/* <Testimotionals /> */}
        <ContactTwo />
      </main>
    </LayoutOne>
  );
};

export default Home;
