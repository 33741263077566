import React from "react";
import { Link } from "react-router-dom";
import logoImage from "../../../src/assets/img/logo/logo2.webp";
import { scrollToTop } from "../../lib/helpers";

const sendEmail = () => {
  // E-posta adresini ve konuyu tanımlayın
  const email = "iletisim@iz360.com.tr";
  const subject = "Konu";

  // E-posta adresini ve konuyu URL ile kodlayın
  const emailLink = "mailto:" + email + "?subject=" + encodeURIComponent(subject);

  // E-posta linkini yeni bir pencerede açın
  window.open(emailLink, "_blank");
};


const FooterOne = () => {
  return (
    <footer>
      <div className="footer-area">
        <div className="container">
          <div className="footer-scroll-wrap">
            <button
              className="scroll-to-target"
              data-target="html"
              onClick={scrollToTop}
            >
              <i className="fas fa-arrow-up"></i>
            </button>
          </div>
          <div className="footer-top">
            <div className="row">
              <div className="col-xl-3 col-lg-4 col-md-6" style={{ justifyContent:"center", display:"flex"}}>
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".2s"
                >
                  <Link to="/" className="f-logo" style={{ justifyContent:"center", display:"flex", marginBottom:"25px"}}>
                    <img src={logoImage} alt="İZ360 Logo" />
                  </Link>
                  <div className="footer-content">
                    <p>
                      İZ360 | Etkinlik Yönetimi, İnovasyon
                    </p>
                    <ul className="footer-social">
                      <li>
                        <a href="https://www.instagram.com/iz360_grup/" target="_blank">
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                      <li>
                        <a href="https://www.linkedin.com/company/iz360" target="_blank">
                          <i className="fab fa-linkedin"></i>
                        </a>
                      </li>
                      <li>
                        <a href="/hiring">
                          <i className="fas fa-users"></i>
                        </a>
                      </li>
                      <li>
                        <a onClick={sendEmail}>
                          <i className="fas fa-envelope"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-3 col-lg-5 col-sm-6">
                <div
                  className="footer-widget  wow fadeInUp"
                  data-wow-delay=".4s"
                >
                  <h4 className="fw-title">Faydalı Linkler</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/prep">İletişim</Link>
                      </li>
                      <li>
                        <Link to="/prep">Portfolyo</Link>
                      </li>
                      <li>
                        <Link to="/prep">Hizmetlerimiz</Link>
                      </li>
                      <li>
                        <Link to="/prep">Hizmet Beyannamesi</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-2 col-lg-3 col-sm-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".6s"
                >
                  <h4 className="fw-title">Topluluk</h4>
                  <div className="footer-link">
                    <ul>
                      <li>
                        <Link to="/prep">SSS</Link>
                      </li>
                      <li>
                        <Link to="/prep">Basında Biz</Link>
                      </li>
                      <li>
                        <Link to="/prep">Galeri</Link>
                      </li>
                      <li>
                        <Link to="/prep">Partnerler</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6">
                <div
                  className="footer-widget wow fadeInUp"
                  data-wow-delay=".8s"
                >
                  <h4 className="fw-title">Haberdar Olun</h4>
                  <div className="footer-newsletter">
                    <p>
                      Mail adresinizi göndererek bültene kayıt olun ve yaptıklarımızdan haberdar olun ! 
                    </p>
                    <form action="#">
                      <input
                        type="email"
                        placeholder="info@gmail.com"
                        required
                      />
                      <button type="submit">
                        <i className="fas fa-paper-plane"></i>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="row align-items-center">
              <div className="col-lg-12" style={{ textAlign:'center'}}>
                <div className="copyright-text">
                  <p>Copyright &copy; | İZ360 2023. Tüm hakları saklıdır</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterOne;
