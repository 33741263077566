import React from "react";
// Import your image file here
import earthImage from "../../assets/videos/earth.gif";

const WhoWeAre = (props) => {
  return (
    <section id="about" className="about-area pb-130">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="about-vid wow fadeInLeft" data-wow-delay=".2s">
              {/* Use an image element */}
              <img className="earth" src={earthImage} alt="Earth" width="80%" />
            </div>
          </div>
          <div className="col-lg-6">
            <div className="about-content wow fadeInRight" data-wow-delay=".2s">
              <div className="section-title">
                <span className="sub-title">HAKKIMIZDA</span>
              </div>
              <p>
                Etkinlik organizasyon yönetimi konusunda dünyada lider bir şirket olmak ve müşterilerimizin unutulmaz deneyimler yaşamasını sağlamak için sürekli olarak yenilikçi ve özgün çözümlerle organizasyonlarınızı düzenliyoruz. <br/><br/>Global vizyonumuz ve misyonumuz doğrultusunda etkinlik organizasyonları alanında öncü bir şirket olarak müşterilerimizin ihtiyaçlarına cevap vermek ve onlara unutulmaz deneyimler yaşatmak için sürekli olarak kendimizi geliştiriyoruz. İleri teknolojilere olan ilgimiz sayesinde Yapay Zeka destekli ürünlerimiz ve akıllı etkileşimlerle etkinlik süreçlerini optimize ediyoruz.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhoWeAre;


// import React, { useEffect } from "react";
// // Import your image file here
// import earthImage from "../../assets/videos/earth.png";

// const WhoWeAre = (props) => {
//   useEffect(() => {
//     const handleScroll = () => {
//       const earthImg = document.querySelector(".earth");
//       const scrollY = window.scrollY;
//       const rotation = scrollY / 10; // Adjust the rotation speed here

//       earthImg.style.transform = `rotate(${rotation}deg)`;
//     };

//     window.addEventListener("scroll", handleScroll);

//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   return (
//     <section id="about" className="about-area pb-130">
//       <div className="container">
//         <div className="row align-items-center">
//           <div className="col-lg-6">
//             <div className="about-vid wow fadeInLeft" data-wow-delay=".2s">
//               {/* Use an image element */}
//               <img className="earth" src={earthImage} alt="Earth" width="100%" />
//             </div>
//           </div>
//           <div className="col-lg-6">
//             <div className="about-content wow fadeInRight" data-wow-delay=".2s">
//               <div className="section-title">
//                 <span className="sub-title">HAKKIMIZDA</span>
//               </div>
//               <p>
//                 Etkinlik organizasyon yönetimi konusunda dünyada lider bir şirket olmak ve müşterilerimizin unutulmaz deneyimler yaşamasını sağlamak için sürekli olarak yenilikçi ve özgün çözümlerle organizasyonlarınızı düzenliyoruz. <br/><br/>Global vizyonumuz ve misyonumuz doğrultusunda etkinlik organizasyonları alanında öncü bir şirket olarak müşterilerimizin ihtiyaçlarına cevap vermek ve onlara unutulmaz deneyimler yaşatmak için sürekli olarak kendimizi geliştiriyoruz. İleri teknolojilere olan ilgimiz sayesinde Yapay Zeka destekli ürünlerimiz ve akıllı etkileşimlerle etkinlik süreçlerini optimize ediyoruz.
//               </p>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default WhoWeAre;
